@tailwind components;
@tailwind utilities;

body {
  background-color: #e3e3e3 !important;
  -webkit-print-color-adjust: exact !important;
  line-height: normal !important;
}

p {
  margin: 0;
}

@media print {
  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

@font-face {
  font-family: "Neue Montreal";
  /* src: url("/fonts/NeueMontreal-Bold.otf");
  src: url("/fonts/NeueMontreal-BoldItalic.otf");
  src: url("/fonts/NeueMontreal-Italic.otf");
  src: url("/fonts/NeueMontreal-Light.otf");
  src: url("/fonts/NeueMontreal-LightItalic.otf"); */
  /* src: url("/fonts/NeueMontreal-MediumItalic.otf"); */
  /* src: url("/fonts/NeueMontreal-Regular.otf"); */
  src: url("/fonts/NeueMontreal-Medium.otf");
  /* font-weight: 400; */
  /* font-style: normal; */
}

.scrollbarSmall::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.scrollbarSmall::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #f5f5f5;
}

.scrollbarSmall::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 1px solid #555555;
}

.react-datepicker-popper {
  z-index: 2005 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.swal2-container {
  z-index: 50000 !important;
}

/* bot comment related */
.bot-comment-block {
  background-color: #f0f0f0;
  margin-bottom: 3px;
}

.bot-comment-block .bot-comment-block-head,
.bot-comment-block .bot-comment-block-foot {
  font-size: 10px;
}

.bot-comment-block .bot-comment-content {
  padding-left: 15px;
}
.bot-comment-block + br,
.bot-comment-block-head + br,
.bot-comment-block br:first-child {
  display: none;
}

.revenueTable {
  border-collapse: collapse;
  text-align: center;
  width: "100%";
  /* border-bottom-style: hidden; */
  border-width: "30px";
  font-weight: bold;
}
.simpleRevenueTable {
  border-collapse: collapse;
  border-color: black;
  text-align: center;
  width: "100%";
  margin: 0px;
  padding: 0px;
}

.revenueTable td {
  border-collapse: collapse;
  border: "5px solid black";
}

.revenueInputField {
  background-color: white;
  padding: 0px;
  margin: 0px;
}

.revenueNoneInputField {
  background-color: rgb(226, 222, 222);
}

.revenueStateCurrencyField {
  background-color: rgb(243, 243, 243);
}

.revenueStatePercentageField {
  font-weight: 100;
}

.revenuePercentageField {
  font-weight: 100;
}

.revenueTableGrayField {
  background-color: rgb(185, 179, 179);
}

.revenueTableTopHeader :not(:first-child) {
  background-color: rgb(185, 179, 179);
}

.revenueTableGrayFieldAlign {
  background-color: rgb(185, 179, 179);
  text-align: right;
  padding-right: 10px;
}

.revenueTotalHeadings {
  background-color: rgb(255, 186, 59);
}

.revenueBottomField {
  background-color: rgb(250, 250, 160);
  font-weight: 700;
}

.revenueTDHidden {
  border-style: hidden;
  /* background-color: white; */
}

.revenueTDQuestionMark {
  border-top-style: hidden;
  border-bottom-style: hidden;
  border-right-style: hidden;
  background-color: white;
}

.amountCell {
  color: rgb(2, 96, 219);
  font-weight: 700;
}

.boldCell {
  font-weight: 700;
}

.actionTypeLabel {
  color: rgb(2, 96, 219);
  font-weight: 700;
}

.revenueInput {
  margin: 0px !important;
  padding-left: 3px !important;
}
.revenueInput input {
  margin: 0px !important;
  padding-right: 2px !important;
}

.revenueInputAdornment {
  margin: 0px !important;
  padding: 2px !important;
}


table.reconciliationTable *{
  padding-left: 3px;
}

/*Tax Filing View*/

.taxFilingViewTable {
  border-collapse: collapse;
  text-align: center;
  width: "100%";
}


.alignment {
  padding-left: 10px;
  text-align: left;
}

/*Tax Filing View*/

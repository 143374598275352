.custom-toolbar-material-table .MuiToolbar-root > div:nth-child(1) {
  display: none;
}
.custom-toolbar-material-table .MuiToolbar-root > div:nth-child(3) > div > div {
  display: flex;
}
.custom-toolbar-material-table div > div > span {
  display: flex;
}

.custom-toolbar-material-table .MuiToolbar-root > div > div > div {
  display: flex;
}
.custom-toolbar-material-table .MuiToolbar-root > div > div {
  display: flex;
}
.custom-toolbar-material-table .MuiToolbar-root {
  background-color: transparent;
}

.custom-toolbar-material-table-container {
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 20;
  border-bottom: 1px solid grey;
}

.custom-toolbar-material-table input[aria-label="Search"] {
  color: blue;
  font-weight: bold;
}

.custom-toolbar-material-table .MuiToolbar-root {
  min-height: auto !important;
}

.data-table thead tr th:first-child {
  border-radius: 15px 0px 0px 0px;
}

.data-table thead tr th:last-child {
  border-radius: 0px 15px 0px 0px;
}

.data-table thead th {
  background-color: #ddd !important;
  font-weight: bold;
  text-align: center !important;
}

.data-table-bordered td {
  border-right: 1px solid #cee2e6;
}

.data-table-bordered th {
  border-right: 2px solid white;
}

.MuiTableSortLabel-root .material-icons {
  display: none;
}

.Mui-active .material-icons {
  display: block;
}

.page-break {
  page-break-inside: avoid;
}

[class*="MuiToolbar-root-MuiTablePagination-toolbar"] {
  height: 30px !important;
  min-height: 30px !important;
  overflow: hidden;
}
[class*="MuiToolbar-root-MuiTablePagination-toolbar"]
  > .MuiTablePagination-selectLabel {
  height: 27px !important;
}

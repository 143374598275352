.bg-primary {
  background: var(--primary) !important;
}
.bg-secondary {
  background: var(--secondary) !important;
}

.bg-error {
  background: var(--error) !important;
}
.bg-white {
  background: #fff !important;
  color: inherit;
}

.bg-dark {
  background: #000000;
  color: #fff;
}
.bg-light-dark {
  background: #212121;
  color: white;
}
.bg-error {
  background: var(--error) !important;
  color: white !important;
}
.bg-green {
  background: #08ad6c !important;
}
.bg-light-primary {
  &::after {
    background: var(--primary);
  }
}
.bg-light-secondary {
  position: relative;
  z-index: 0;
  &::after {
    background: var(--secondary);
  }
}
.bg-light-error {
  position: relative;
  z-index: 0;
  &::after {
    background: var(--error);
  }
}

[class^="bg-light-"],
[class*=" bg-light-"] {
  position: relative;
  z-index: 0;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.15;
    z-index: -1;
    border-radius: 8px;
  }
}

.bg-transperant {
  background: transparent !important;
}

.text-white {
  color: #fff !important;
}
.text-white-secondary {
  color: rgba(#fff, 0.87) !important;
}
.text-muted-white {
  color: rgba(#fff, 0.54) !important;
}
.text-light-white {
  color: rgba(255, 255, 255, 0.54) !important;
}

.text-gray {
  color: rgba(0, 0, 0, 0.74) !important;
}

.text-primary {
  color: var(--primary) !important;
}
.text-secondary {
  color: var(--secondary) !important;
}

.text-small {
  font-size: 10px;
}
.text-medium {
  font-size: 16px;
}
.text-large {
  font-size: 20px;
}

.text-blue {
  color: var(--color-blue) !important;
}
.text-blue-dark {
  color: blue !important;
}
.text-error {
  color: var(--error) !important;
}
.text-success {
  color: green !important;
}

.gray-on-hover {
  transition: background 250ms ease;
  &:hover {
    background: rgba(0, 0, 0, 0.054);
  }
}

// Border color
.border-color-white {
  border-color: #ffffff !important;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-c-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
}

.flex-v-center {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.text-bold {
  font-weight: bold !important;
}
.text-bolder {
  font-weight: bolder !important;
}

.bordered-table {
  border: 2px solid white;
  border-collapse: collapse;
  margin: 4px 2px;
  width: 100%;
}
.bordered-table th {
  border: 2px solid white;
  border-collapse: collapse;
  padding: 5px 10px;
  background-color: #b9b3b3;
  color: black;
}
.bordered-table tr {
  border: 2px solid white;
  border-collapse: collapse;
}
.bordered-table td {
  border: 2px solid white;
  border-collapse: collapse;
  padding: 3px 10px;
}
.bordered-table .footer {
  background-color: #b9b3b3;
  color: black;
  font-weight: bold;
}
:root {
  --stats-green: #c8facd;
  --stats-blue: #d0f2ff;
  --stats-yellow: #fff7cd;
  --stats-red: #ffe7d9;
  --stats-t-green: #005249;
  --stats-t-blue: #04297a;
  --stats-t-yellow: #7a4f01;
  --stats-t-red: #7a0c2e;
}
.stats {
  &-card {
    height: 17.5rem;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: column;
    &-image {
      width: 4rem;
      height: 4rem;
      border-radius: 500px;
      background-image: linear-gradient(
        135deg,
        rgba(183, 129, 3, 0) 0%,
        rgba(183, 129, 3, 0.24) 100%
      );
      display: flex;
      justify-content: center;
      align-items: center;
      & img {
        object-fit: cover;
        max-width: 85%;
        max-height: 85%;
      }
    }
    &-content {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      &__title {
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 700;
      }
      &__subtitle {
        font-size: 1rem;
        padding: 0.5rem 0;
      }
    }
  }
}

#salesComparisonReport th {
  border: 2px solid black;
  padding: 3px;
  background-color: lightgray;
}
#salesComparisonReport td {
  border: 1px solid black;
  padding: 3px;
}

#salesComparisonReport table {
  width: 100%;
  border-spacing: 0px;
}
